import React from 'react'

const Segment = ({ angle, radius, ...rest }) => {
  const dx = radius * Math.sin(angle)
  const dy = radius * (1 - Math.cos(angle))

  return (
    <path
      {...rest}
      d={`M${radius} ${radius}V0a${radius} ${radius} 0 0 1 ${dx} ${dy}z`}
    />
  )
}

export const PieChart = ({ data = [], radius = 100, colors = null, hueStep = 20, ...rest } = {}) => {
  const total = data.reduce((a, b) => a + b, 0)
  const diameter = 2 * radius
  let rotate = 0.5 * Math.PI

  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      {data.map((value, i) => {
        const angle = (2 * Math.PI * value) / total

        const fill = colors && i < colors.length ? colors[i] : `hsl(${hueStep * i}, 100%, 60%)`
        const transform = `rotate(${rotate}rad)`
        const transformOrigin = `${radius}px ${radius}px`

        rotate += angle

        return (
          <Segment
            angle={angle}
            radius={radius}
            style={{ fill, transform, transformOrigin }}
            {...rest}
          />
        )
      })}
    </svg>
  )
}
