import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import {
  Section,
  Row,
  Col,
  Container,
  Responsive,
} from '../components/containers/Layout'
import {
  FinePrint,
  H2,
  H3,
  Heading,
  Paragraph,
  Quote,
} from '../components/base/Typography'
import { Link } from 'gatsby'
import Button from '../components/base/Button'
import MealCard from '../components/meal/MealCard'
import bl1 from '../media/brooke-lark-96398-unsplash.jpg'
import jp from '../media/jennifer-pallian-306915-unsplash.jpg'
import j from '../media/photo-1528992761365-34a53564c222.jpeg'
import breakfast from '../media/alexander-mils-365917-unsplash.jpg'
import { List, ListItem } from '../components/containers/List'
import { Card } from '../components/base/Card'
import Tag from '../components/Tag'
import Polaroid, { Photo } from '../components/base/Polaroid'
import hotdog from '../media/hotdogs.jpeg'
import Image from '../components/base/Image'
import bitesnap from '../media/bitesnap-cropped.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faSearch,
  faCheck,
  faChartLine,
  faPlug,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import Contact from '../components/Contact'
import { BREAKFAST_RESPONSE } from '../data/api-responses'
import { RecognitionExample } from './food-recognition'
import { PieChart } from '../components/charts/PieChart'
import ItemTag from '../components/item/ItemTag'
import { Flipper, Flipped } from 'react-flip-toolkit'
// - see it in action (Bitesnap)

import { Feature } from '../components/base/Feature'
import { StackedIcon } from '../components/base/StackedIcon'

const Testimonial = ({ rating, message, name }) => (
  <div
    style={{
      padding: '10px 16px',
      border: '1px solid #EEE',
      borderRadius: 8,
      margin: 6,
    }}
  >
    <FontAwesomeIcon icon={faStar} color={'gold'} />
    <FontAwesomeIcon icon={faStar} color={'gold'} />
    <FontAwesomeIcon icon={faStar} color={'gold'} />
    <FontAwesomeIcon icon={faStar} color={'gold'} />
    <FontAwesomeIcon icon={faStar} color={'gold'} />
    <Paragraph short style={{ margin: 2, padding: 2 }}>
      {message}
    </Paragraph>
  </div>
)

const Hero = () => (
  <Section
    style={{
      paddingBottom: 200,
      paddingTop: 100,
    }}
  >
    <Container wide>
      <Col
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {/*<img src={logo} height={120}/>*/}
        <Heading
          large
          align={'left'}
          style={{ marginTop: 20, marginBottom: 20, fontSize: '2.2rem' }}
        >
          Automate Food Logging <br />
          With Machine Learning
        </Heading>
        <Paragraph align={'left'} narrow>
          Add automated food input and meal tracking to your app with a computer
          vision based solution that cuts down the time to track from minutes to
          seconds a day.
        </Paragraph>
        {/* <Link to="/request-demo">
          <Button size={'xl'} style={{ margin: '20px 0' }}>
            Book A Demo
          </Button>
        </Link> */}
      </Col>
      <Col>
        <Photo src={j} />
      </Col>
    </Container>
  </Section>
)

const SocialProof = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col>Loved</Col>
    </Container>
  </Section>
)

class ExampleSwitcher extends React.Component {
  static defaultProps = {}
  state = { index: 0 }

  render() {}
}


const ImageRecognition = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col className={'sm-full'}>
        <Col style={{ justifyContent: 'center' }}>
          <Row justify="center">
            <Polaroid path={'hanny-naibaho-313771-unsplash.jpg'} size={300} />
          </Row>
          {/*<FinePrint bold inline>Suggestions</FinePrint>*/}
          <Flipper flipKey={0}>
          <Row wrap="wrap" justify="center">
            {BREAKFAST_RESPONSE.items.slice(0, 8).map(x => (
              <ItemTag
                key={x.item.id}
                item={x.item}
                inline
                style={{ opacity: x.score * 8 }}
              />
            ))}
          </Row>
          </Flipper>
        </Col>
      </Col>
      <Col>
        <H2>Intelligent Suggestions</H2>
        <Paragraph narrow>
          Use machine learning to automate each step of the food logging
          process.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Recognize Thousands Of Foods</H3>
            <Paragraph narrow>
              Our food recognition model recognizes thousands of most commonly
              eaten foods, making logging a meal as simple as taking a picture
              and confirming suggestions.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Learn To Recognize Previously Logged Meals</H3>
            <Paragraph narrow>
              The system learns to recognize previously logged meals so repeat
              meals take a second to log.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Interactively Predict What's Missing</H3>
            <Paragraph narrow>
              Use selected suggestions and contextual information to
              interactively update and improve the suggestions, making it easy
              to log items that are not visible in the image.
            </Paragraph>
          </ListItem>
        </List>
{/* 
        <Link to={'/request-demo'}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const Search = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse>
      <Col className={'sm-full'}>
        <div
          style={{
            // backgroundColor: '#FDFDFD',
            borderRadius: 12,
            padding: '30px 20px',
            border: '1px solid #EEE',
          }}
        >
          <Card lifted style={{ minWidth: '100%' }}>
            <div
              style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}
            >
              <FinePrint bold>Search</FinePrint>
              <H3
                style={{
                  borderRight: '2px solid lightblue',
                  display: 'inline',
                  paddingRight: 2,
                }}
              >
                Special K
              </H3>
            </div>
          </Card>
          <div style={{ padding: 20 }}>
            <FinePrint bold>Your Meals</FinePrint>
            <H3>
              <mark
                style={{
                  backgroundColor: 'transparent',
                  borderBottom: '1px dashed lightblue',
                }}
              >
                Special K
              </mark>{' '}
              Cereal
            </H3>
            <FinePrint>You had this yesterday</FinePrint>
            <H3>
              <mark
                style={{
                  backgroundColor: 'transparent',
                  borderBottom: '1px dashed lightblue',
                }}
              >
                Special K
              </mark>{' '}
              Red Berries
            </H3>
            <FinePrint>You had this last week for breakfast</FinePrint>
          </div>
          <div style={{ padding: '0 20px' }}>
            <FinePrint bold>All Items</FinePrint>
            <H3>
              <mark
                style={{
                  backgroundColor: 'transparent',
                  borderBottom: '1px dashed lightblue',
                }}
              >
                Special K
              </mark>{' '}
              Cereal
            </H3>
            <FinePrint>120 Calories | High Fiber</FinePrint>
            <H3>
              <mark
                style={{
                  backgroundColor: 'transparent',
                  borderBottom: '1px dashed lightblue',
                }}
              >
                Special K
              </mark>{' '}
              Red Berries
            </H3>
            <FinePrint>You had this last week for breakfast</FinePrint>
          </div>
        </div>
      </Col>
      <Col>
        <H2>Smart Search</H2>
        <Paragraph narrow>
          Support quick meal entry without images using text or barcode search.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Instant Type Ahead Results</H3>
            <Paragraph narrow>
              Search as you type means that most items show up in the first few
              strokes
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Contextualized Ranking</H3>
            <Paragraph narrow>
              Use contextual information like time of day and eating preferences
              to improve search result ranking.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Personalized To Each User</H3>
            <Paragraph narrow>
              Faceted resuts that highlight previously logged items.
            </Paragraph>
          </ListItem>
        </List>
      </Col>
    </Container>
  </Section>
)

const DetailedNutritionInfo = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Col className={'sm-full'} style={{ paddingRight: 40 }}>
        <Card lifted style={{ maxWidth: 450, overflow: 'hidden' }}>
          <div
            style={{
              padding: '20px 30px',
              borderBottom: '1px solid #EEE',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'inline-block' }}>
              <FinePrint bold>Name</FinePrint>
              <H3>Special K Cereal</H3>
            </div>

            <div style={{ display: 'inline-block' }}>
              <FinePrint bold>Brand</FinePrint>
              <H3>Kellogg's</H3>
            </div>
          </div>

          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Parents</FinePrint>
            <Tag>Breakfast Cereal</Tag>
          </div>
          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Children</FinePrint>
            <Tag>Special K Red Berries</Tag>
            <Tag>Special K Vanilla Almond</Tag>
            <Tag>Special K Chocolatey Delight</Tag>
            <Tag>Special K Cinnamon Pecan</Tag>
          </div>
          <div style={{ padding: '20px 30px', borderBottom: '1px solid #EEE' }}>
            <FinePrint bold>Ingredients</FinePrint>
            <FinePrint bold>
              Rice, whole grain wheat, sugar, wheat bran, freeze-dried
              strawberries, soluble wheat fiber, ...
            </FinePrint>
          </div>

          <div
            style={{
              borderBottom: '1px solid #EEE',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {/*<div style={{ display: 'inline-block' }}>*/}
            {/*<PieChart data={[1, 2, 2]} radius={30} />*/}
            {/*</div>*/}

            <div style={{ display: 'inline-block', padding: '20px 30px' }}>
              <FinePrint bold>Calories</FinePrint>
              <H2 inline>110</H2>
            </div>
            <div style={{ display: 'inline', padding: '20px 30px' }}>
              <FinePrint bold>Total Fat</FinePrint>
              <H2 inline>0g</H2>
            </div>
            <div style={{ display: 'inline', padding: '20px 30px' }}>
              <FinePrint bold>Total Carb</FinePrint>
              <H2 inline>27g</H2>
            </div>
            <div style={{ display: 'inline', padding: '20px 30px' }}>
              <FinePrint bold>Protein</FinePrint>
              <H2 inline>2g</H2>
            </div>
          </div>
        </Card>
      </Col>

      <Col>
        <H2>Food Knowledge Graph</H2>
        <Paragraph narrow>
          Understand what users are eating with our continuously expanding Food
          Knowledge Graph.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Full Nutritional Breakdown</H3>
            <Paragraph narrow>
              Nutrition infromation includes calories, macros and
              micronutrients.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Ingredients and Multiple Serving Sizes</H3>
            <Paragraph narrow>
              Categorized by dietary trends, cuisine, preparation method and
              restrictions.
            </Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo'}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
    </Container>
  </Section>
)

const CustomizedToEachUser = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col>
        <H2>Personalized to Each User</H2>
        <Paragraph narrow>
          Automatically learns to recognize previously submitted meals and
          improves as you use it.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Learns to Recognize Past Meals</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
          <ListItem>
            <H3>Refine Results</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
          <ListItem>
            <H3>Uses Context</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
        </List>
        {/* <Link to={'/request-demo'}>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </Link> */}
      </Col>
      <Row className={'sm-full'} wrap="no-wrap">
        <div>
          <Responsive>
            {size => (
              <Polaroid
                src={hotdog}
                size={size ? Math.max(250, Math.min(300, size.width / 3)) : 300}
              />
            )}
          </Responsive>
        </div>
        {/*<div style={{padding: 5}}>*/}
        {/*{VISION_RESPONSE.items*/}
        {/*.slice(0, 6)*/}
        {/*.map(x => (*/}
        {/*<ItemTag*/}
        {/*key={x.item.id}*/}
        {/*item={x.item}*/}
        {/*inline*/}
        {/*style={{opacity: x.score * 8}}*/}
        {/*/>*/}
        {/*))}*/}
        {/*</div>*/}
      </Row>
    </Container>
  </Section>
)

const FeaturesList = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container wide reverse={reverse} style={{ alignItems: 'flex-start' }}>
      <Col>
        <StackedIcon icon={faStar} size={3} style={{ marginBottom: 20 }} />
        <H2>Feature Packed</H2>
        <Paragraph narrow>
          Includes everything that's needed to add food logging to your app.
        </Paragraph>
        <div>
          <Feature>User Accounts</Feature>
          <Feature>Backend Storage</Feature>
          <Feature>Image Hosting</Feature>
          <Feature>Barcode Scanning</Feature>
          <Feature>Offline Support</Feature>
        </div>
      </Col>
      <Col>
        <StackedIcon icon={faPlug} size={3} style={{ marginBottom: 20 }} />
        <H2>Easy To Integrate</H2>
        <Paragraph narrow>
          Integration is easy with our RESTful and GraphQL APIs.
        </Paragraph>
        <div>
          <Feature>GraphQL API</Feature>
          <Feature>RESTful API</Feature>
          <Feature>Cross Platform SDK</Feature>
          <Feature>UI Kit</Feature>
          <Feature>Documentation</Feature>
        </div>
      </Col>
      <Col>
        <StackedIcon icon={faChartLine} size={3} style={{ marginBottom: 20 }} />
        <H2>Always Improving</H2>
        <Paragraph narrow>
          We're always working on improving the SDK and adding new features.
        </Paragraph>
        <div>
          <Feature>Recognition Accuracy</Feature>
          <Feature>Recognition Coverage</Feature>
          <Feature>Growing Food Database</Feature>
          <Feature>Learned Search Ranking</Feature>
          <Feature>New Features</Feature>
        </div>
      </Col>
    </Container>
  </Section>
)

const Bitesnap = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container wide reverse={reverse} style={{ alignItems: 'flex-start' }}>
      <Col>
        <Row>
          <Heading style={{ textAlign: 'center' }}>
            Powering Bitesnap
            <br />
            Our Top Rated Food Logging App
          </Heading>
        </Row>

        <Row style={{ padding: 0, border: '1px solid #EEE', borderRadius: 8, boxShadow: '1px 5px 15px -2px rgba(0,0,0,0.15)' }}>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-end',
              padding: '10px 10px 0 10px',
            }}
          >
            <img src={bitesnap} width={300} />
          </Col>

          <Col>
            <Testimonial
              message={
                'The AI is accurate, fast, and makes keeping track of my food easy and quick.'
              }
            />
            <Testimonial
              message={
                "This app is truly magical. Addresses an inconvenience that many deal with when embarking on food logging with technology, just take a photo and it magically figures out what you're eating."
              }
            />
            <Testimonial
              message={'Awesome app! Easiest way to keep a food journal.'}
            />
            <Testimonial
              message={
                ' It is fantastic to be able to snap a picture of your plate, log each item, and instantly get the calories and nutritional information.'
              }
            />
          </Col>
        </Row>
      </Col>
    </Container>
  </Section>
)

const FoodLoggingPage = () => (
  <Layout>
    <Helmet
      title={'Bite AI - Food Logging SDK'}
      meta={[
        {
          name: 'description',
          content: 'Food Recognition API',
        },
        {
          property: 'og:description',
          content: 'Food Recognition API',
        },
        {
          name: 'keywords',
          content:
            'food recognition, api, deep learning, image recognition,' +
            'food recognition api, food search, food image recognition,' +
            ' food knowledge graph',
        },
      ]}
    />
    <Hero />
    <ImageRecognition />
    <Search />
    <DetailedNutritionInfo />
    {/*<CustomizedToEachUser />*/}
    <Bitesnap />
    <FeaturesList />
    {/*<SDK />*/}

    {/* <Contact
      heading={'Get Started'}
      paragraph={'Contact us to learn more about the SDK and request access.'}
      product={'Food Logging SDK'}
    /> */}
  </Layout>
)

export default FoodLoggingPage
